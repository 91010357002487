
import './App.css';
import { Link } from 'react-router-dom';


function App() {

  const ButtonLink = ({to, children}) => {
    return (
      <Link to={to}><button className="h-100 border-2 border-pink py-3 px-6 bg-gray-dark/75 hover:bg-pink transition duration-250">{children}</button></Link>
    )
  }

  // const imageArray = [
  //   { src: 'navy_blue_hooded_top2_product_pic_sml.jpg', link: '', alt: 'hoodie' }
  // ]
  return (
    <div className="App bg-gray-900">
      <div className="w-full h-dvh bg-gray-dark">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-0 bg-gray-dark auto-rows-max">
          <div className={`flex justify-center text-6xl border-0 p-6 bg-gray-900 h-80 bg-logo overflow-hidden bg-cover bg-center`} >
            <div className="flex flex-col text-white p-3 md:p-3 lg:p-6 opacity-50 "><p className="font-bold text-5xl leading-tight md:leading-none lg:leading-tight ">
              "Only a lasting legacy can defeat father time"
            </p>
              <div className="text-sm mt-3 text-right">
                — About Paper
              </div>
            </div>
          </div>
          <div className={`flex justify-center text-6xl border-0 bg-gray-900 h-80 card-zoom`} >
         <div className="w-full h-full card-zoom-image bg-blueTop bg-cover bg-top"></div>
          </div>
          <div className={`flex justify-center text-6xl border-0 p-6 bg-gray-900 h-80 bg-jump bg-cover`}></div>
          <div className="flex justify-center text-6xl text-white  p-6 bg-gray-900 h-80 items-center">
            <p className="font-bold text-9xl opacity-25">
            A
            </p>
         
          </div>
          <div className={`flex justify-center text-6xl border-0 p-6 bg-gray-900 h-80 
          bg-trainer
           bg-cover bg-right-bottom items-center`}>
          <div className="text-xl text-white">
            <ButtonLink to="https://shop.aboutpaper.co.uk">
              <p className="font-bold">
              SHOP NOW
              </p>
              </ButtonLink>
            </div>
          </div>

          <div className="flex justify-center text-6xl text-white  p-6 bg-gray-900 h-80 items-center">
            <p className="font-bold text-9xl opacity-25">
            P
            </p>
           
          </div>
          <div className={`flex justify-center text-6xl border-0 bg-gray-900 h-80 card-zoom`}>
            <div className="bg-cover bg-puffer card-zoom-image h-full w-full"></div>
          </div>
          <div className="flex justify-center text-6xl text-white  p-6 bg-gray-900 h-80 items-center">
          </div>
          <div className={`flex justify-center text-6xl border-0 p-6 bg-gray-900 h-80 bg-hoodie bg-cover bg-right`}></div>
        </div>
      </div>
    </div>
  );
}

export default App;
