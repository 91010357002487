import React from 'react'

const About = () => {
    return (
        <>
        About us page
        </>
    )
}

export default About